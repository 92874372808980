<template>
  <PageWithLayout :isBodyFull="true">
    <BoxView title="group category" :grid="100">
      <div class="group_category">
        <GroupCategory
          :groupCategoryData.sync="categoryData.depth1"
          :dataList.sync="categoryData.depth1.dataList"
          :value.sync="categoryData.depth1.value"
          :upperData="{
            title : 'select',
            value : selectData.value,
          }"
          @onClickAdd="categoryId => onClickAdd(categoryId)"
          @onClickCategoryItem="(categoryId,rowData) => onSelectCategoryItem(categoryId,rowData)"
          @onModifyOrder="orderData => onModifyOrder(orderData)">
          <template v-slot:top_category>
            <div class="area_select">
              <Selectbox
                :dataList="selectData.dataList"
                :value.sync="selectData.value"
                :showLine="5"
                @update:value="onUpdateSelect()"/>
            </div>
          </template>
          <template v-slot:item_category="slotProps">
            <span class="txt_item">
              <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
            </span>
            <span class="txt_item">
              <span class="box_color" :style="`background-color:${ $options.filters.convertIdToColor(slotProps.itemData.status, status_color) }`"></span>
            </span>
            <strong class="tit_item">{{ slotProps.itemData.name }}</strong>
            <span class="txt_count fill_item">({{ slotProps.itemData.count }})</span>
            <span class="txt_item">{{!slotProps.itemData.checked ? '⚠️': ''}}</span>
          </template>
        </GroupCategory>
        <GroupCategory
          :groupCategoryData.sync="categoryData.depth2"
          :dataList.sync="categoryData.depth2.dataList"
          :value.sync="categoryData.depth2.value"
          :upperData="{
            title : categoryData.depth1.title,
            value : categoryData.depth1.value,
          }"
          @onClickAdd="categoryId => onClickAdd(categoryId)"
          @onClickCategoryItem="(categoryId,rowData) => onSelectCategoryItem(categoryId,rowData)"
          @onModifyOrder="orderData => onModifyOrder(orderData)">
          <template v-slot:top_category>
            <div class="tab_comm">
              <button
                v-for="tab in tabData.dataList"
                :style="{width:100/tabData.dataList.length+'%'}"
                :key="`tab${tab.id}`"
                :class="['btn_tab', { active : tab.id === tabData.value }]"
                @click.stop="onClickTab(tab.id)">{{ tab.text }}<span class="count_tab">({{ tab.count || 0 }})</span></button>
            </div>
          </template>
          <template v-slot:item_category="slotProps">
            <span v-if="tabData.value === 'tab1'" class="txt_item">
              <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
            </span>
            <strong class="tit_item">{{ slotProps.itemData.name }}</strong>
            <span class="txt_count fill_item">({{ slotProps.itemData.count }})</span>
            <span class="txt_item">{{!slotProps.itemData.checked ? '⚠️': ''}}</span>
          </template>
        </GroupCategory>
        <GroupCategory
          :groupCategoryData.sync="categoryData.depth3"
          :dataList.sync="categoryData.depth3.dataList"
          :value.sync="categoryData.depth3.value"
          :upperData="{
            title : categoryData.depth2.title,
            value : categoryData.depth2.value,
          }"
          @onClickAdd="categoryId => onClickAdd(categoryId)"
          @onClickCategoryItem="(categoryId,rowData) => onSelectCategoryItem(categoryId,rowData)"
          @onModifyOrder="orderData => onModifyOrder(orderData)">
          <template v-slot:top_category>
            <div class="area_status">
              <dl
                v-if="statusData.count"
                :class="['item_status', statusData.count.data[statusData.count.value].className ]">
                <dt>상태1</dt>
                <dd>
                  <IconSvg
                    :iconName="statusData.count.data[statusData.count.value].iconName"
                    :size="7"
                    :iconColor="statusData.count.data[statusData.count.value].iconColor"
                    :iconDarkColor="statusData.count.data[statusData.count.value].iconDarkColor"
                    :bgcolor="statusData.count.data[statusData.count.value].bgcolor"
                  />{{ statusData.count.data[statusData.count.value].desc }} ({{ categoryData.depth3.dataList.length }}/5)
                </dd>
              </dl>
              <dl :class="['item_status', statusData.checked ? 'item_green' : 'item_red' ]">
                <dt>상태2</dt>
                <dd>
                  <IconSvg
                    v-if="statusData.checked"
                    iconName="check"
                    :size="7"
                    iconColor="#F3F8F3"
                    iconDarkColor='#1A1F1A'
                    bgcolor="#388E3B"
                  /><IconSvg
                    v-else
                    iconName="cross"
                    :size="7"
                    iconColor="#FEF4F4"
                    iconDarkColor='#251B1B'
                    bgcolor="#F04848"
                  />{{ statusData.checked ? '검수완료' : '미검수 존재' }}
                </dd>
              </dl>
            </div>
          </template>
          <template v-slot:item_category="slotProps">
            <template v-if="tabData.value === 'tab1'">
              <span class="txt_item">
                <IconSvg iconName="drag" :size="18" iconColor="#bbb" />
              </span>
              <strong class="tit_item fill_item">{{ slotProps.itemData.name }}</strong>
              <span class="txt_item txt_receipt">
                <IconSvg v-if="slotProps.itemData.checked" iconName="check" :size="10" iconColor="#fff" bgcolor="#388E3B" />
                <IconSvg v-else iconName="cross" :size="8" iconColor="#fff" bgcolor="#F04848" />
              </span>
            </template>
            <template v-if="tabData.value === 'tab2'">
              <strong class="tit_item fill_item">{{ slotProps.itemData.name }}</strong>
              <span class="txt_item"><IconSvg :iconName="slotProps.itemData.quizContentType | wordToLowerCase" :size="18" :iconColor="slotProps.itemData.quizContentType | convertIdToColor('mission_quizContentType')" /></span>
              <span class="txt_item txt_receipt">
                <IconSvg v-if="slotProps.itemData.checked" iconName="check" :size="10" iconColor="#fff" bgcolor="#388E3B" />
                <IconSvg v-else iconName="cross" :size="8" iconColor="#fff" bgcolor="#F04848" />
              </span>
            </template>
          </template>
        </GroupCategory>
      </div>
    </BoxView>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';

import GroupCategory from '@lemontree-ai/lemontree-admin-common-front/components/common/category/GroupCategory'
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';

export default {
  name:'Category',
  components:{
    PageWithLayout,
    BoxView,

    GroupCategory,
    IconSvg,
    Selectbox,
  },
  data(){
    return{
      status_color:[
        { id:'DRAFT', text:'#F04848' },
        { id:'PUBLIC', text:'#388E3B' },
        { id:'CLOSED', text:'#CCCCCC' },
      ],
      selectData:{
        dataList:[
          { id:'select1', text:'select1' },
          { id:'select2', text:'select2' },
          { id:'select3', text:'select3' },
          { id:'select4', text:'select4' },
          { id:'select5', text:'select5' },
          { id:'select6', text:'select6' },
          { id:'select7', text:'select7' },
          { id:'select8', text:'select8' },
        ],
        value:'select1'
      },
      tabData:{
        dataList:[
          { id:'tab1', text:'tab1', count:0 },
          { id:'tab2', text:'tab2', count:0 },
          { id:'tab3', text:'tab3', count:0 },
        ],
        value:'tab1'
      },
      statusData:{
        count:{
          data:{
            OVER:{
              className:'item_red',
              desc: '초과',
              iconName: 'cross',
              iconColor: '#FEF4F4',
              iconDarkColor: '#251B1B',
              bgcolor: '#F04848',
            },
            UNDER:{
              className:'item_bule',
              desc: '미달',
              iconName: 'cross',
              iconColor: '#F3F4FA',
              iconDarkColor: '#1A1B21',
              bgcolor: '#3F51B4',
            },
            DEFAULT:{
              className:'item_green',
              desc: '적절',
              iconName: 'check',
              iconColor: '#F3F8F3',
              iconDarkColor: '#1A1F1A',
              bgcolor: '#388E3B',
            },
          },
          value:'OVER'
        },
        check:false
      },
      categoryData:{
        depth1:{
          title : '대분류',
          id : 'depth1',
          index : 0,
          titleList: [
            { title:'order' },
            { title:'status' },
            { title:'title (count)', isFill: true },
            { title:'checked' },
          ],
          dataList: [],
          contextMenu: [
            { icon:'✍️', text:'수정', event:(data) => this.onClickContextModify(data) },
            { icon:'🗑', text:'삭제', event:(data) => this.onClickContextDelete(data) },
          ],
          isDragable:true,
          isAddBtn:true,
          isSearchBtn:true,
          value:null
        },
        depth2:{
          title : '중분류',
          id : 'depth2',
          index : 1,
          titleList: [
            { title:'order' },
            { title:'title (count)', isFill: true },
            { title:'checked' },
          ],
          dataList: [],
          contextMenu: [
            { icon:'✍️', text:'수정', event:(data) => this.onClickContextModify(data) },
            { icon:'🗑', text:'삭제', event:(data) => this.onClickContextDelete(data) },
          ],
          isDragable:true,
          isAddBtn:true,
          isSearchBtn:true,
          value:null
        },
        depth3:{
          title : '소분류',
          id : 'depth3',
          index : 2,
          titleList: [
            { title:'order' },
            { title:'title', isFill: true },
            { title:'checked' },
          ],
          dataList: [],
          contextMenu: [
            { icon:'✍️', text:'수정', event:(data) => this.onClickContextModify(data) },
            { icon:'🗑', text:'삭제', event:(data) => this.onClickContextDelete(data) },
          ],
          isDragable:true,
          isAddBtn:true,
          isSearchBtn:true,
          value:null
        }
      },
      contentSearchOpenId:''
    }
  },
  mounted(){
    this.getDepth1Data(this.selectData.value);
  },
  methods:{
    onClickAdd(categoryId){
      console.log('onClickAdd',categoryId)
    },
    // 항목 선택
    onSelectCategoryItem(categoryId,rowData){
      let beforeValue = null;
      let setValueEvent = undefined;
      let getDataEvent = undefined;

      switch(categoryId){
        case 'PLACE' : {
          beforeValue = this.dataViewModel.PLACE.value;
          setValueEvent = () => {
            this.dataViewModel.PLACE.value = rowData.id;
          };
          getDataEvent = () => {
            this.dataViewModel.getCourseList(rowData.id)
          };
          rowData = { id : rowData };
          break
        }
        case 'depth1' : {
          beforeValue = this.categoryData.depth1.value;
          setValueEvent = () => {
            this.categoryData.depth1.value = rowData.id;
          };
          getDataEvent = () => {
            this.getDepth2Data(rowData.id,this.tabData.value)
          };
          break
        }
        case 'depth2' : {
          beforeValue = this.categoryData.depth2.value;
          setValueEvent = () => {
            this.categoryData.depth2.value = rowData.id;
          };
          getDataEvent = () => {
            this.getDepth3Data(rowData.id)
          };
          break
        }
        case 'depth3' : {
          // beforeValue = this.categoryData.depth3[this.categoryData.QUEST.type.value].value;
          // setValueEvent = () => {
          //   this.categoryData.depth3[this.categoryData.QUEST.type.value].value = rowData.id;
          // };
          // getDataEvent = () => {
          //   const callBack = () => {
          //     this.groupCategoryDrawData.depth3[this.dataViewModel.QUEST.type.value].isRegisterPopup = true;
          //   }
          //   this.dataViewModel.getSubjectData(rowData.id,callBack);
          //   return;
          // };
          break
        }
      }
      if(beforeValue != rowData.id){
        setValueEvent();
        getDataEvent();
      }else if(categoryId === 'SUBJECT'){
        setValueEvent();
        getDataEvent();
      }
    },
    onModifyOrder(orderData){
      console.log('onModifyOrder',orderData)
    },
    onUpdateSelect(){
      this.getDepth1Data(this.selectData.value)
    },
    onClickTab(tabValue){
      this.tabData.value = tabValue;
      this.getDepth2Data(this.categoryData.depth1.value,this.tabData.value);
      this.categoryData.depth3.dataList = [];
    },
    onClickContextModify(data){
      console.log(data)
    },
    onClickContextDelete(data){
      console.log(data)
    },
    getDepth1Data(id){
      const resultData = {
        select1:[
          {
            id:'id1',
            status : 'DRAFT',
            name : 'title_01',
            count : 5,
            checked : false
          },
          {
            id:'id2',
            status : 'PUBLIC',
            name : 'title_02',
            count : 2,
            checked : true
          },
          {
            id:'id3',
            status : 'CLOSED',
            name : 'title_03',
            count : 0,
            checked : false
          }
        ],
        select2:[],
      }
      this.categoryData.depth1.dataList = resultData[id] || [];
    },
    getDepth2Data(id,tabId){
      const resultData = {
        id1:{
          tab1:[
            {
              id:'id1_1_1',
              name : 'title_01_01_01',
              count : 5,
              checked : false
            },
            {
              id:'id1_1_2',
              name : 'title_01_01_02',
              count : 5,
              checked : true
            },
            {
              id:'id1_1_3',
              name : 'title_01_01_03',
              count : 2,
              checked : false
            },
            {
              id:'id1_1_4',
              name : 'title_01_01_04',
              count : 3,
              checked : false
            },
            {
              id:'id1_1_5',
              name : 'title_01_01_05',
              count : 0,
              checked : false
            }
          ],
          tab2:[
            {
              id:'id1_2_1',
              name : 'title_01_02_01',
              count : 1,
              checked : false
            },
            {
              id:'id1_2_2',
              name : 'title_01_02_02',
              count : 0,
              checked : true
            },
          ],
          tab3:[
            {
              id:'id1_3_1',
              name : 'title_01_03_01',
              count : 0,
              checked : false
            },
            {
              id:'id1_3_2',
              name : 'title_01_03_02',
              count : 0,
              checked : true
            },
          ]
        },
        id2:{
          tab1:[
            {
              id:'id2_1_1',
              name : 'title_02_01',
              count : 0,
              checked : true
            },
            {
              id:'id2_2_2',
              name : 'title_02_02',
              count : 0,
              checked : true
            },
          ],
          tab2:[],
          tab3:[],
        },
        id3:{
          tab1:[],
          tab2:[],
          tab3:[],
        }
      }
      this.categoryData.depth2.dataList = resultData[id][tabId];
    },
    getDepth3Data(id){
      const resultData = {
        id1_1_1:[
          {
            id:'id1_1_1_1',
            name : 'title_01_01_01_01',
            checked : true
          },
          {
            id:'id1_1_1_2',
            name : 'title_01_01_01_02',
            checked : true
          },
          {
            id:'id1_1_1_3',
            name : 'title_01_01_01_03',
            checked : false
          },
          {
            id:'id1_1_1_4',
            name : 'title_01_01_01_04',
            checked : false
          },
          {
            id:'id1_1_1_5',
            name : 'title_01_01_01_05',
            checked : false
          }
        ],
        id1_1_2:[
          {
            id:'id1_1_2_1',
            name : 'title_01_01_02_01',
            checked : true
          },
          {
            id:'id1_1_2_2',
            name : 'title_01_01_02_02',
            checked : true
          },
          {
            id:'id1_1_2_3',
            name : 'title_01_01_02_02',
            checked : true
          },
          {
            id:'id1_1_2_4',
            name : 'title_01_01_02_02',
            checked : true
          },
          {
            id:'id1_1_2_5',
            name : 'title_01_01_02_02',
            checked : true
          },
        ],
        id1_1_3:[
          {
            id:'id1_1_3_1',
            name : 'title_01_01_03_01',
            checked : true
          },
          {
            id:'id1_1_3_2',
            name : 'title_01_01_03_02',
            checked : true
          },
        ],
        id1_1_4:[
          {
            id:'id1_1_4_1',
            name : 'title_01_01_04_01',
            checked : true
          },
          {
            id:'id1_1_4_2',
            name : 'title_01_01_04_02',
            checked : true
          },
          {
            id:'id1_1_4_3',
            name : 'title_01_01_04_03',
            checked : true
          },
        ],
        id1_1_5:[],
        id1_2_1:[
          {
            id:'id1_2_1_1',
            name : 'title_01_02_01_01',
            quizContentType: 'AUTOCHECK',
            checked : true
          },
        ],
        id1_2_2:[],
        id1_3_1:[],
        id1_3_2:[],
        id2_1_1:[],
        id2_2_2:[],
      }
      this.categoryData.depth3.dataList = resultData[id];
    },
  }
}
</script>
<style scoped>
.search_comm{flex:0 0 37px;margin-bottom:20px}
.box_view{height:calc(100% + 20px)}
.box_view::v-deep .body_view{height:calc(100% - 54px);box-sizing:border-box}
.group_category{flex:1 1 100%;height:100%;overflow:hidden;overflow-x:auto;white-space:nowrap}
.group_category .area_category{width:33.33%}
</style>